export const reviews = {
  average: 4.8,
  total: 245,
  bestReviews: [
    {
      id: 1,
      user: '패션좋아',
      rating: 5,
      content: '허리가 너무 편하고 다리가 길어보여요! 오피스룩으로 딱이에요',
      images: ['https://picsum.photos/400/400?random=1', 'https://picsum.photos/400/400?random=2'],
      helpful: 42,
      verified: true,
      date: '2024.01.15',
      size: 'S',
      color: '블랙',
      height: '163cm',
    },
    {
      id: 2,
      user: '스타일리시',
      rating: 5,
      content: '핏이 너무 예쁘고 소재도 좋아요. 여름에도 입을 수 있을 것 같아요!',
      images: ['https://picsum.photos/400/400?random=3'],
      helpful: 38,
      verified: true,
      date: '2024.01.14',
      size: 'M',
      color: '베이지',
      height: '168cm',
    },
  ],
  reviews: [
    {
      id: 3,
      user: '데일리룩',
      rating: 4,
      content: '전체적으로 만족스러워요. 다만 생각보다 기장이 조금 긴 감이 있어요.',
      images: [],
      helpful: 15,
      verified: true,
      date: '2024.01.13',
      size: 'S',
      color: '블랙',
      height: '160cm',
    },
    {
      id: 4,
      user: '쇼핑러버',
      rating: 5,
      content: '일주일 정도 입어봤는데 정말 편하고 예뻐요! 블랙 색상이라 어디에나 잘 어울려요.',
      images: ['https://picsum.photos/400/400?random=4', 'https://picsum.photos/400/400?random=5', 'https://picsum.photos/400/400?random=6'],
      helpful: 27,
      verified: true,
      date: '2024.01.12',
      size: 'M',
      color: '블랙',
      height: '165cm',
    },
  ],
};
